<template>
    <form
        @submit.prevent="validateForm(formScope)"
        :data-vv-scope="formScope"
        :ref="formScope"
    >
        <PurposeOfProcessing
            :formScope="formScope"
            :create="create"
            :countryCode="countryCode"
            :currentUiLanguage="currentUiLanguage"
            @help-mode="$emit('help-mode', $event)"
        />
    </form>
</template>

<script>
import PurposeOfProcessing from './PurposeOfProcessing';

export default {
    name: 'Step3',
    components: {
        PurposeOfProcessing,
    },
    props: {
        create: {
            type: Boolean,
            default: false,
        },
        template: {
            type: Boolean,
            default: false,
        },
        formScope: {
            type: String,
            default: null
        },
        countryCode: {
            type: String,
            default: null,
        },
        currentUiLanguage: {
            type: String,
            default: null
        },
    }
};
</script>
