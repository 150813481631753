<template>
    <v-container fluid class="pl-1 pt-0 pb-3 mr-n1" :class="{'mb-3': !isCollapsed && lastIndex !== i, 'mt-3': !isCollapsed && i > 0}">
        <v-row class="mb-1 elevation-1">
            <v-toolbar short flat color="tertiary" class="cursor-pointer"
                @click="collapseItem(isCollapsed ? 'show' : 'hide', i)"
            >
                <v-toolbar-title class="pl-0 ml-n2 text-subtitle-1">
                    <v-icon color="primary" class="v-icon--collapse" size="28" v-if="isCollapsed" v-text="'mdi-unfold-more-horizontal'" />
                    <v-icon color="primary" class="v-icon--collapse" size="28" v-else v-text="'mdi-unfold-less-horizontal'" />
                    <span
                        class="v-toolbar-title-text pl-2"
                        :class="{
                            'error--text': hasError,
                        }"
                        >{{ $t('vvtProcessEditor.purposeOfProcessing.headline') }}
                        {{ i + 1 }}
                        <template v-if="groupName">: {{ groupName }}</template>
                    </span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip v-if="hasError" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="error" class="ma-2"
                            >warning</v-icon
                        >
                    </template>
                    <span v-html="$t('error')"></span>
                </v-tooltip>
                <v-tooltip v-if="i > 0" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            class="mx-2"
                            small
                            fab
                            depressed
                            outlined
                            @click.stop="moveItem('up')"
                        >
                            <v-icon>keyboard_arrow_up</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'moveUp'" />
                </v-tooltip>
                <v-tooltip v-if="i < lastIndex" bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            v-on="on"
                            color="primary"
                            class="mx-2"
                            small
                            fab
                            depressed
                            outlined
                            @click.stop="moveItem('down')"
                        >
                            <v-icon>keyboard_arrow_down</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'moveDown'" />
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            class="mx-2"
                            color="primary"
                            @click.stop="commentMode = true"
                            v-on="on"
                            small
                            fab
                            depressed
                            :outlined="!hasComments"
                            :disabled="!hasComments && !canEdit"
                        >
                            <v-icon
                                small
                                :color="hasComments ? 'white' : 'primary'"
                                >mdi-comment-outline</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'comments.buttonLabel'"></span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            class="mx-2"
                            color="success"
                            @click.stop="duplicateItem()"
                            v-on="on"
                            small
                            fab
                            depressed
                            outlined
                        >
                            <v-icon small>js-icon-copy</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'duplicate'" />
                </v-tooltip>
                <v-tooltip bottom color="error">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="!canEdit"
                            @click.stop="removeItemConfirmation = true"
                            class="mx-2"
                            color="error"
                            v-on="on"
                            small
                            fab
                            depressed
                            outlined
                        >
                            <v-icon small>clear</v-icon>
                        </v-btn>
                    </template>
                    <span v-t="'remove'" />
                </v-tooltip>
            </v-toolbar>
        </v-row>
        <v-row
            v-show="!isCollapsed"
            style="margin-top:-16px;"
            class="tertiary px-2 mb-1 fill-height elevation-1"
            align="stretch"
            justify="space-around"
        >
            <v-col cols="12" class="py-0">
                <v-card-title 
                    class="pl-0 pb-0 text-subtitle-1"
                    v-t="'vvtProcessEditor.purposeOfProcessing.purposeHeadline'" />
            </v-col>
            <v-col cols="12">
                <LeaTextArea
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="3"
                    :rowId="rowId"
                    fieldName="purposeOfProcessing"
                    childFieldName="purpose"
                    :label="$t('vvtProcessEditor.purposeOfProcessing.purpose.label')"
                    :hint="$t('vvtProcessEditor.purposeOfProcessing.purpose.hint')"
                    :helpModeText="$t('vvtProcessEditor.purposeOfProcessing.purpose.helpMode')"
                    :create="create"
                    v-model="purposeModel"
                    rows="2"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12" class="pb-0">
                <v-card-title class="pa-0 text-subtitle-1" v-t="'vvtProcessEditor.purposeOfProcessing.legalityHeadline'" />
            </v-col>
            <v-col cols="12">
                <BaseInputMultiOption
                    :disabled="!canEdit"
                    v-model="legalityModel"
                    fieldName="purposeOfProcessing"
                    childFieldName="legality"
                    :label="$t('vvtProcessEditor.purposeOfProcessing.legality.label')"
                    :hint="$t('vvtProcessEditor.purposeOfProcessing.legality.hint')"
                    :helpModeText="$t('vvtProcessEditor.purposeOfProcessing.legality.helpMode')"
                    :items="legalityItems"
                    :itemsHelpMode="$t('vvtProcessEditor.purposeOfProcessing.legality.items')"
                    :rowId="rowId"
                    :formScope="formScope"
                    :step="3"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <template v-if="legalityModel">
            <v-col cols="12">
                <LeaTextArea
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="3"
                    :rowId="rowId"
                    fieldName="purposeOfProcessing"
                    childFieldName="legalityNote"
                    :label="$t('vvtProcessEditor.purposeOfProcessing.legalityNote.label')"
                    :hint="$t('vvtProcessEditor.purposeOfProcessing.legalityNote.hint')"
                    :helpModeText="$t('vvtProcessEditor.purposeOfProcessing.legalityNote.helpMode')"
                    :create="create"
                    v-model="legalityNoteModel"
                    rows="2"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            <v-col cols="12">
                <LeaUpload
                    :disabled="!canEdit"
                    :formScope="formScope"
                    :step="3"
                    :rowId="rowId"
                    fieldName="purposeOfProcessing"
                    childFieldName="legalityEnclosure"
                    :label="$t('vvtProcessEditor.purposeOfProcessing.legalityEnclosure.label')"
                    :hint="$t('vvtProcessEditor.purposeOfProcessing.legalityEnclosure.hint')"
                    :helpModeText="$t('vvtProcessEditor.purposeOfProcessing.legalityEnclosure.helpMode')"
                    :removeHint="$t('remove')"
                    :create="create"
                    v-model="legalityEnclosureModel"
                    @help-mode="$emit('help-mode', $event)"
                />
            </v-col>
            </template>
        </v-row>
        <v-row v-show="!isCollapsed" class="elevation-1 mt-0 mb-1">
            <v-col cols="12" class="pa-0">  
                <v-expansion-panels
                    v-model="expertAreaModel"
                    class="mr-3"
                    flat
                >
                    <v-expansion-panel hideActions>
                        <v-expansion-panel-header
                            class="text-subtitle-1 primary--text px-4"
                            color="tertiary"
                            :expand-icon="null"
                            :class="{
                                'font-weight-bold': expertAreaModel === 0,
                                'font-weight-medium': expertAreaModel !== 0,
                            }"
                        >
                            <div>
                                <v-icon
                                    color="primary"
                                    class="pr-2"
                                    :class="{ 'rotate-90': expertAreaModel === 0 }"
                                    >keyboard_arrow_right</v-icon
                                >
                                {{ $t('vvtProcessEditor.purposeOfProcessing.expertArea') }}
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="tertiary">
                            <LegitimateInterest
                                v-if="hasLegitimateInterest"
                                :disabled="!canEdit"
                                :formScope="formScope"
                                componentScope="purposeOfProcessing"
                                :i="i"
                                :rowId="rowId"
                                v-model="legitimateInterestModel"
                                stepNum="3"
                                :currentUiLanguage="currentUiLanguage"
                                :ref="formScope + '_purposeOfProcessing_legitimate_interest_' + rowId"
                                @help-mode="$emit('help-mode', $event)"
                                fieldName="purposeOfProcessing"
                                childFieldName="legitimateInterest"
                            />
                            <v-row
                                class="pa-2 pt-6 pb-0 mb-1"
                                justify="space-around"
                            >
                                <v-col cols="12" v-if="hasLegalObligation">
                                    <LeaDropdown
                                        type="autocomplete"
                                        :disabled="!canEdit"
                                        v-model="nationalLegalObligationModel"
                                        :items="nationalLegalObligationItems"
                                        :formScope="formScope"
                                        :step="3"
                                        :rowId="rowId"
                                        clearable
                                        fieldName="purposeOfProcessing"
                                        childFieldName="nationalLegalObligation"
                                        :label="$t('vvtProcessEditor.purposeOfProcessing.nationalLegalObligation.label')"
                                        :hint="$t('vvtProcessEditor.purposeOfProcessing.nationalLegalObligation.hint')"
                                        :helpModeText="$t('vvtProcessEditor.purposeOfProcessing.nationalLegalObligation.helpMode')"
                                        :create="create"
                                        @help-mode="$emit('help-mode', $event)"
                                    />
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <LeaDropdown
                                        type="autocomplete"
                                        :disabled="!canEdit"
                                        v-model="flexibilityClauseModel"
                                        :items="flexibilityClauseItems"
                                        :formScope="formScope"
                                        :step="3"
                                        :rowId="rowId"
                                        fieldName="purposeOfProcessing"
                                        childFieldName="flexibilityClause"
                                        :label="$t('vvtProcessEditor.purposeOfProcessing.flexibilityClause.label')"
                                        :hint="$t('vvtProcessEditor.purposeOfProcessing.flexibilityClause.hint')"
                                        :helpModeText="$t('vvtProcessEditor.purposeOfProcessing.flexibilityClause.helpMode')"
                                        :create="create"
                                        @help-mode="$emit('help-mode', $event)"
                                        multiple
                                        chips
                                        deletableChips
                                        enable-select-all
                                    >
                                        <template v-slot:item="{ parent, item }" subheader>
                                            <template v-if="typeof item !== 'object'">
                                                <v-list-item>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </template>

                                            <template v-else>
                                                <v-list-item-action>
                                                    <v-checkbox
                                                        color="primary"
                                                        :input-value="parent.hasItem(item)"
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                                <v-spacer />
                                                <v-list-item-action>
                                                    <v-tooltip left color="primary">
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon v-on="on" color="primary"
                                                                >info</v-icon
                                                            >
                                                        </template>
                                                        <span
                                                            ><strong>{{ item.title }}:</strong>
                                                            {{ item.description }}</span
                                                        >
                                                    </v-tooltip>
                                                </v-list-item-action>
                                            </template>
                                        </template>
                                        <template v-slot:selection="{ item, parent, selected }">
                                            <v-tooltip v-if="item === Object(item)" bottom color="primary" z-index="100">
                                                <template v-slot:activator="{ on }">
                                                    <v-chip
                                                        :input-value="selected"
                                                        class="v-chip--removable"
                                                        :class="{'v-chip--disabled': !canEdit}"
                                                        v-on="on"
                                                    >
                                                        {{ item.title }}
                                                        <v-btn v-if="canEdit" icon @click="parent.selectItem(item)" class="ml-3 mr-n3 v-chip__close">
                                                            <v-icon size="18"
                                                                >mdi-close-circle</v-icon
                                                            >
                                                        </v-btn>
                                                    </v-chip>
                                                </template>
                                                <span><strong>{{ item.title }}<span v-if="item.description">:</span></strong>{{ item.description }}</span>
                                            </v-tooltip>
                                        </template>
                                    </LeaDropdown>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <LeaDropdown
                                        v-if="countryCode"
                                        :type="$getDropdownTypeFor('nationalDataProtectionLaw', getCompanyGroupSettings('enabledUserFreetextInputFields'))"
                                        return-object
                                        :disabled="!canEdit"
                                        v-model="nationalDataProtectionLawModel"
                                        :items="filteredNationalDataProtectionLawItems"
                                        :formScope="formScope"
                                        :step="3"
                                        :rowId="rowId"
                                        fieldName="purposeOfProcessing"
                                        childFieldName="nationalDataProtectionLaw"
                                        :label="$t('vvtProcessEditor.purposeOfProcessing.nationalDataProtectionLaw.label')"
                                        :hint="$t('vvtProcessEditor.purposeOfProcessing.nationalDataProtectionLaw.hint')"
                                        :helpModeText="$t('vvtProcessEditor.purposeOfProcessing.nationalDataProtectionLaw.helpMode')"
                                        :create="create"
                                        @help-mode="$emit('help-mode', $event)"
                                        multiple
                                        chips
                                        deletableChips
                                        enable-select-all
                                        :delimiters="[',']"
                                        :freetext-enabled-hint="$t('createItemHintComma')"
                                    />
                                    <v-card
                                        v-else
                                        align-start
                                        class="default"
                                        height="100%"
                                        outlined
                                    >
                                        <v-card-text v-t="'vvtProcessEditor.purposeOfProcessing.nationalDataProtectionLaw.creationNote'" />
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <BaseConfirmationDialog
            v-model="removeItemConfirmation"
            :title="$t('rowItem.removeConfirmation.headline')"
            :text="$t('rowItem.removeConfirmation.text')"
            :confirmationText="$t('rowItem.removeConfirmation.delete')"
            :cancelText="$t('rowItem.removeConfirmation.cancel')"
            @cancel="removeItemConfirmation = false"
            @confirm="removeItem()"
        />
        <CommentsDialog
            v-model="commentMode"
            :generalComments="generalComments"
            :internalComments="internalComments"
            @data="setCommentsToModel($event)"
            :disabled="!canEdit"
        />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModelMixin from '@/components/vvt/ModelMixin';
import LeaTextArea from '@/components/Input/LeaTextArea';
import LeaUpload from '@/components/Input/LeaUpload';
import LeaDropdown from '@/components/Input/LeaDropdown';
import LegitimateInterest from '@/components/LegitimateInterest';
import CommentsDialog from '@/components/vvt/ProcessEditor/CommentsDialog.vue';

export default {
    name: 'PurposeOfProcessingLegality',
    mixins: [ModelMixin],
    components: {
        LeaTextArea,
        LeaUpload,
        LeaDropdown,
        LegitimateInterest,
        CommentsDialog,
    },
    inject: {
        $validator: '$validator',
    },
    props: {
        formScope: {
            type: String,
            default: null
        },
        i: {
            type: Number,
            default: null
        },
        rowId: {
            type: [Number, String],
            default: null,
        },
        lastIndex: {
            type: Number,
            default: null
        },
        create: {
            type: Boolean,
            default: false,
        },
        countryCode: {
            type: String,
            default: null,
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        currentUiLanguage: {
            type: String,
            default: null
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/ProcessEditor/en.json'),
            de: require('@/locales/vvt/ProcessEditor/de.json'),
        },
    },
    data() {
        return {
            highlightCollapseButton: false,
            removeItemConfirmation: false,
            expertAreaModel:
                this.hasLegitimateInterest || this.hasLegalObligation
                    ? 0
                    : null,
            commentMode: false,
        };
    },
    computed: {
        ...mapGetters({
            getCompanyGroupSettings: 'companyGroupSettings/get',
            legalityItems: 'legality/getItems',
            nationalLegalObligationItems: 'nationalLegalObligation/getItems',
            flexibilityClauseItems: 'flexibilityClause/getCategoriesWithItems',
            get: 'processingActivityModel/getChildProperty',
            nationalDataProtectionLawItems: 'nationalDataProtectionLaw/getItems',
        }),
        filteredNationalDataProtectionLawItems () {
            return this.nationalDataProtectionLawItems.filter(
                x => !x.country || x.country === this.countryCode.toUpperCase()
            );
        },
        purposeOfProcessing: {
            get() {
                return this.get({
                    property: 'purposeOfProcessing',
                    child: this.i,
                });
            },
            set(val) {
                this.set({
                    property: 'purposeOfProcessing',
                    child: this.i,
                    data: val,
                });
            },
        },
        purposeModel: {
            get() {
                return this.purposeOfProcessing.purpose ? this.purposeOfProcessing.purpose.title : null;
            },
            set(val) {
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ purpose: { title: val} },
                };
                this.purposeOfProcessing = merged;
            },
        },
        groupName() {
            return this.purposeModel ? this.purposeModel : null;
        },
        legalityModel: {
            get() {
                return this.purposeOfProcessing.legality;
            },
            set(val) {
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ legality: val },
                };
                this.purposeOfProcessing = merged;

                if (this.hasLegitimateInterest || this.hasLegalObligation) {
                    this.openExpertAreaModel();
                }
            },
        },
        legalityNoteModel: {
            get() {
                return this.purposeOfProcessing.legalityNote;
            },
            set(val) {
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ legalityNote: val },
                };
                this.purposeOfProcessing = merged;
            },
        },
        legalityEnclosureModel: {
            get() {
                return this.purposeOfProcessing.legalityEnclosure;
            },
            set(val) {
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ legalityEnclosure: val },
                };
                this.purposeOfProcessing = merged;
            },
        },
        nationalLegalObligationModel: {
            get() {
                return this.getValue(this.nationalLegalObligationItems, this.purposeOfProcessing.nationalLegalObligation);
            },
            set(val) {
                if (typeof val === 'object' && val !== null && val.id) {
                    val = val.id;
                } else if (typeof val === 'string') {
                    val = val.replace(/^\s+|\s+$/g, '').replace(/\s\s/g, ' ');
                }
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ nationalLegalObligation: val },
                };
                this.purposeOfProcessing = merged;
            },
        },
        legitimateInterestModel: {
            get() {
                return this.purposeOfProcessing.legitimateInterest;
            },
            set(val) {
                this.setLegitimateInterest({
                    index: this.i,
                    data: val,
                });
            },
        },
        flexibilityClauseModel: {
            get() {
                return this.getValue(this.flexibilityClauseItems, this.purposeOfProcessing.flexibilityClause)
            },
            set(val) {
                const data = this.setValue(this.flexibilityClauseItems, val);
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ flexibilityClause: data },
                };
                this.purposeOfProcessing = merged;
            },
        },
        nationalDataProtectionLawModel: {
            get() {
                return this.getValue(this.nationalDataProtectionLawItems, this.purposeOfProcessing.nationalDataProtectionLaw);
            },
            set(val) {
                const data = this.setValue(
                    this.nationalDataProtectionLawItems,
                    val,
                    null,
                    {
                        country: this.countryCode
                            ? this.countryCode.toUpperCase()
                            : null,
                    },
                    'nationalDataProtectionLaw/unshiftItems'
                );
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ nationalDataProtectionLaw: data },
                };
                this.purposeOfProcessing = merged;
            },
        },
        generalComments: {
            get() {
                return this.purposeOfProcessing.generalComments;
            },
            set(val) {
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ generalComments: val },
                };
                this.purposeOfProcessing = merged;
            },
        },
        internalComments: {
            get() {
                return this.purposeOfProcessing.internalComments;
            },
            set(val) {
                const merged = {
                    ...this.purposeOfProcessing,
                    ...{ internalComments: val },
                };
                this.purposeOfProcessing = merged;
            },
        },
        hasComments() {
            return (
                !!(
                    (this.generalComments &&
                        this.generalComments.trim().length) ||
                    (this.internalComments &&
                        this.internalComments.trim().length)
                ) === true
            );
        },
        legalityMarked() {
            if (
                this.$route.query.mark !== undefined &&
                this.$route.query.mark === 'legality'
            ) {
                return true;
            }
            return false;
        },
        purposeMarked() {
            if (
                this.$route.query.mark !== undefined &&
                this.$route.query.mark === 'purposeOfProcessing'
            ) {
                return true;
            }
            return false;
        },
        hasLegitimateInterest() {
            const legalityItem = this.legalityItems.find(
                x => x.id === this.purposeOfProcessing.legality
            );
            return legalityItem && legalityItem.legitimateInterest
                ? legalityItem.legitimateInterest
                : false;
        },
        hasLegalObligation() {
            const legalityItem = this.legalityItems.find(
                x => x.id === this.purposeOfProcessing.legality
            );
            return legalityItem && legalityItem.legalObligation
                ? legalityItem.legalObligation
                : false;
        },
        hasError() {
            if (
                this.$validator.errors.has(
                    'purposeOfProcessingPurpose' + this.rowId,
                    this.formScope
                )
            ) {
                return true;
            }
            return false;
        },
    },
    methods: {
        ...mapActions({
            set: 'processingActivityModel/setChildProperty',
            remove: 'processingActivityModel/removePurposeOfProcessing',
            add: 'processingActivityModel/addPurposeOfProcessing',
            move: 'processingActivityModel/movePurposeOfProcessing',
            addLegitimateInterest:
                'processingActivityModel/addPurposeOfProcessingLegitimateInterest',
            setLegitimateInterest:
                'processingActivityModel/setPurposeOfProcessingLegitimateInterest',
        }),
        openExpertAreaModel() {
            this.expertAreaModel = 0;
        },
        removeItem() {
            this.remove(this.i).then(this.$emit('collapseAll'));
        },
        duplicateItem() {
            this.add({
                item: this.purposeOfProcessing,
                index: this.i + 1,
            })
                .then(this.$emit('collapseAll'))
                .then(this.collapseItem('show', this.i + 1));
        },
        moveItem(dir) {
            this.move({
                from: this.i,
                to: dir === 'up' ? this.i - 1 : this.i + 1,
            }).then(this.$emit('collapseAll'));
        },
        collapseItem(type, index) {
            this.$emit('collapse', {
                type: type,
                index: index,
            });
            this.calculateInputHeights();
        },
        calculateInputHeights() {
            const legitimateInterest = this.$refs[
                this.formScope +
                    '_purposeOfProcessing_legitimate_interest_' +
                    this.rowId
            ];
            if (legitimateInterest) {
                legitimateInterest.calculateInputHeight();
            }
        },
        setCommentsToModel(val) {
            this.generalComments = val.generalComments;
            this.internalComments = val.internalComments;
        },
    },
    created() {
        // create legitimateInterest object if it not exists
        if (!this.purposeOfProcessing.legitimateInterest) {
            this.addLegitimateInterest(this.i);
        }

        if (this.hasLegitimateInterest || this.hasLegalObligation) {
            this.openExpertAreaModel();
        }
    },
}
</script>
